import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement, useEffect, useState } from 'react';
import { httpRequest } from '../../../../../../../client-js/common/domutil';
import { Network, PopulatedCompany, ReviewSource } from '../../../../../../../types/entities.types';
import { CompanyId, MicrositeLocalPageProps } from '../../index';
import { trimText } from '../../tools/';
import {
    Banner,
    CompanyCardLocation,
    CompanyCardRating,
    CompanyCardSummary,
    CompanyCardTitle,
    CompanyDetailContainer,
    Container,
    PlainLink,
    ReviewSourceArrowIcon,
    ReviewSourceIcon,
    ReviewSourceIconImage,
    ReviewSourceLink,
    ReviewSourceRow,
    ReviewSourceSeeMoreLink,
    ReviewSourceTitle,
} from './CompanyCard.styles';

export interface ICompanyCardProps extends CompanyId {
    MICROSITE_DOMAIN: MicrositeLocalPageProps['MICROSITE_DOMAIN'];
    SDK_DOMAIN: MicrositeLocalPageProps['SDK_DOMAIN'];
}

interface ICompanyDetailData {
    company: Pick<PopulatedCompany, 'company_name' | 'slug' | 'id' | 'address' | 'logo_photo'>;
    review_sources: Array<
        ReviewSource & {
            networkData: Network;
        }
    >;
    overall_review_stats: {
        rating_sum: number;
        review_count: number;
        rating_average: number;
    };
}

const CompanyCard = React.memo(function CompanyCard({
    id,
    MICROSITE_DOMAIN,
    SDK_DOMAIN,
}: ICompanyCardProps): ReactElement | null {
    const [loading, setLoading] = useState<boolean>(true);
    const [result, setResult] = useState<ICompanyDetailData | null>(null);
    useEffect(() => {
        loadCompanyDetailData();
    }, []);

    function loadCompanyDetailData(): void {
        httpRequest<{ id: string }, ICompanyDetailData>({
            method: 'POST',
            url: `${SDK_DOMAIN}/sdk/company-detail`,
            body: {
                id,
            },
        })
            .then(res => {
                setLoading(false);
                setResult(res);
            })
            .catch(error => {
                setLoading(false);
                setResult(null);
            });
    }

    if (!result && !loading) {
        return null;
    }

    if (loading) {
        return <Container loading={true}></Container>;
    }

    // @ts-ignore
    const { review_sources, overall_review_stats, company } = result;
    const { logo_photo } = company;

    return (
        <Container loading={loading}>
            <PlainLink href={`${MICROSITE_DOMAIN}/${company.slug}`}>
                <Banner
                    url={
                        logo_photo?.url ||
                        'https://media.nicejob.co/company_logos/32-nicejob@2x.png'
                    }
                />
            </PlainLink>
            <CompanyDetailSection
                overall_review_stats={overall_review_stats}
                company={company}
                MICROSITE_DOMAIN={MICROSITE_DOMAIN}
            />
            <ReviewSourceSection
                company={company}
                MICROSITE_DOMAIN={MICROSITE_DOMAIN}
                review_sources={review_sources}
            />
        </Container>
    );
});

export default CompanyCard;

/**
 * Company Detail
 * - Name
 * - Rating and reviews summary
 * - Location
 */
function CompanyDetailSection({
    overall_review_stats,
    company,
    MICROSITE_DOMAIN,
}: Pick<ICompanyDetailData, 'overall_review_stats' | 'company'> &
    Pick<ICompanyCardProps, 'MICROSITE_DOMAIN'>): ReactElement {
    const company_name = company.company_name ? trimText(company.company_name, 16) : '';

    return (
        <CompanyDetailContainer>
            <PlainLink href={`${MICROSITE_DOMAIN}/${company.slug}`}>
                <CompanyCardTitle>{company_name}</CompanyCardTitle>
            </PlainLink>
            {overall_review_stats.review_count > 0 && (
                <CompanyCardSummary>
                    {overall_review_stats.rating_average.toFixed(1)}
                    <CompanyCardRating>
                        {starsRating(overall_review_stats.rating_average)}
                    </CompanyCardRating>
                    {overall_review_stats.review_count}{' '}
                    {translate({
                        namespace: 'microsite',
                        key: 'local.reviews',
                        params: { count: overall_review_stats.review_count },
                    })}
                </CompanyCardSummary>
            )}
            <CompanyCardLocation>{companyAddress(company.address)}</CompanyCardLocation>
        </CompanyDetailContainer>
    );
}

/**
 * Review network
 * List of first two review network
 * see more link goes to company/invite
 */

function ReviewSourceSection({
    company,
    MICROSITE_DOMAIN,
    review_sources,
}: Pick<ICompanyCardProps, 'MICROSITE_DOMAIN'> &
    Pick<ICompanyDetailData, 'review_sources' | 'company'>): ReactElement {
    return (
        <div>
            {review_sources.map((review_source): ReactElement | null => {
                let href =
                    review_source.profile?.write_review_url || review_source.profile?.review_url;

                // Do not render review_sources missing write_review_url/review_url
                if (!href) {
                    return null;
                }

                // add query string to directly open nicejob review form
                if (review_source.network === 'nicejob') {
                    href += '?network=nicejob';
                }

                return (
                    <ReviewSourceRow key={review_source.id}>
                        <ReviewSourceLink href={href}>
                            <ReviewSourceIcon>
                                <ReviewSourceIconImage
                                    src={review_source.networkData?.icon_url || ''}
                                />
                            </ReviewSourceIcon>
                            <ReviewSourceTitle>
                                {translate({
                                    namespace: 'microsite',
                                    key: 'local.leave_review',
                                    params: { network: review_source.networkData?.name },
                                })}
                            </ReviewSourceTitle>
                            <ReviewSourceArrowIcon></ReviewSourceArrowIcon>
                        </ReviewSourceLink>
                    </ReviewSourceRow>
                );
            })}
            <ReviewSourceSeeMoreLink href={`${MICROSITE_DOMAIN}/${company.slug}/invite`}>
                {translate({ namespace: 'common', key: 'text.see_more_opts' })}
            </ReviewSourceSeeMoreLink>
        </div>
    );
}

/** Helpers function */
function starsRating(average_rating: number): string {
    const FULL_STARS = '';
    const HALF_STARS = '';
    const average_difference = average_rating - Math.floor(average_rating);
    const has_half_stars = average_difference < 1 && average_difference > 0;
    const stars = [];
    for (let i = 0; i < Math.floor(average_rating); i++) {
        stars.push(FULL_STARS);
    }

    return stars.join('') + (has_half_stars ? HALF_STARS : '');
}

function companyAddress(address: ICompanyDetailData['company']['address']): string {
    const { address: full_address, city, state, country } = address;

    const first_part = `${full_address || ''} ${city || ''}`.trim();
    const second_part = `${state || ''} ${country || ''}`.trim();

    if (first_part && second_part) {
        return `${first_part}, ${second_part}`;
    }

    return first_part || second_part;
}
