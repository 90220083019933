import { translate } from '@nicejob-library/internationalization';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { Container, Icon, Input } from '../Header/SearchInput.styles';

export interface ISearchInputProps {
    is_mobile: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Component: Search input
 */
export default function SearchInput(props: ISearchInputProps): ReactElement {
    const { is_mobile, onChange: parentOnChange } = props;
    /**
     * State
     */
    const [search_value, setSearchValue] = useState('');

    /**
     * Methods
     */
    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const input = e.currentTarget || e.target;
        const { value = '' } = input;
        setSearchValue(value);
        parentOnChange(e);
    };

    return (
        <Container is_mobile={is_mobile}>
            <Icon></Icon>
            <Input
                type='text'
                placeholder={translate({ namespace: 'microsite', key: 'header.search' })}
                onChange={onChange}
                value={search_value}
            />
        </Container>
    );
}
