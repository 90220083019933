import styled from 'styled-components';
import { IMasonryProps } from './Masonry';

export const MasonryContainer = styled.div`
    display: flex;
`;

export const MasonryColumn = styled.div<Pick<IMasonryProps, 'screen'>>`
    flex: 1;
    margin: ${(props): string => getMasonryColumn(props.screen)};
`;

export const MasonryCard = styled.div`
    margin-bottom: 8px;
`;

function getMasonryColumn(screen: IMasonryProps['screen']): string {
    if (screen === 'MOBILE_PORTRAIT') {
        return '0';
    } else {
        return '0 4px';
    }
}
