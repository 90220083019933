import styled from 'styled-components';
import { ISearchInputProps } from './SearchInput';

const search_width = '320px';
const input_left_pad = '30px';

export const Container = styled.div<Pick<ISearchInputProps, 'is_mobile'>>`
    position: relative;
    display: inline-block;
    height: 40px;
    ${(props): string => getContanierStyle(props.is_mobile)}
`;

export const Input = styled.input`
    display: block;
    line-height: 36px;
    margin-left: ${input_left_pad};
    width: calc(100% - ${input_left_pad});
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px;
    background-color: transparent;
    border: 0;
`;

export const Icon = styled.span`
    position: absolute;
    top: calc(50% + 3px);
    transform: translateY(-50%);
    left: 12px;
    font-family: Nicejob v2;
    font-size: 12px;
    color: #a6acb6;
`;

function getContanierStyle(is_mobile: ISearchInputProps['is_mobile']): string {
    if (is_mobile) {
        return `
            width: 100%;
            margin-top: 0px;
            border-bottom: 1px solid #DDDFE3;
            margin-right: 0;
        `;
    }

    return `
        width: ${search_width};
        border-radius: 18px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border: 1px solid white;
        margin-top: 23px;
        margin-right: 32px;
    `;
}
