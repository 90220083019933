import styled, { keyframes, css, FlattenSimpleInterpolation } from 'styled-components';

const place_holder_shimmer = keyframes`
0% {
    background-position: 7% 50%;
}
50% {
    background-position: 18% 50%;
}
100% {
    background-position: 4% 50%;
}
`;

export const Container = styled.div<{ loading: boolean }>`
    ${(props): FlattenSimpleInterpolation | string => {
        return props.loading
            ? css`
                  background-image: linear-gradient(90deg, #dddfe3, #fafafb 20%);
                  background-size: 400% 400%;
                  -webkit-animation: ${place_holder_shimmer} 1s linear infinite;
                  -moz-animation: ${place_holder_shimmer} 1s linear infinite;
                  animation: ${place_holder_shimmer} 1s linear infinite;
              `
            : '';
    }}
    width: 100%;
    display: inline-block;
    margin: 0;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 3px 0px 0, 0, 0, 0.12, 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px 0px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    text-align: center;
    vertical-align: top;
    min-height: 300px;
    background: #ffffff;
`;

export const Banner = styled.div<{ url: string }>`
    width: calc(100% - 10px);
    height: 160px;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(${(props): string => props.url});
    margin: 5px;
`;

export const CompanyCardTitle = styled.h3`
    font-family: Roboto;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #1e2024;
    margin-bottom: 4px;
    margin-top: 16px;
`;

export const CompanyCardSummary = styled.div`
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #1e2024;
    margin-bottom: 4px;
`;

export const CompanyCardRating = styled.span`
    font-family: Font Awesome;
    font-weight: 900;
    font-size: 14px;
    line-height: 10px;
    color: #ffcc00;
    letter-spacing: 0.15em;
    text-align: left;
    margin-bottom: 4px;
    margin-left: 8px;
    margin-right: 8px;
`;

export const CompanyCardLocation = styled.p`
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #828b98;
    margin-bottom: 24px;
    margin-top: 0px;
    padding: 0 5px;
`;

export const ReviewSourceArrowIcon = styled.span`
    float: right;
    margin-right: 16px;
    font-family: Font Awesome;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    transition: margin-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    -webkit-transition: margin-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    -moz-transition: margin-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
`;

export const ReviewSourceRow = styled.div`
    border-top: 1px solid #eceef0;
    height: 32px;
    padding: 16px;
    text-align: left;
    &:hover {
        height: 65px;
        text-align: left;
        -moz-box-shadow: 13px 8px 10px 0 rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
            0 5px 5px -3px rgba(0, 0, 0, 0.14);
        -webkit-box-shadow: 13px 8px 10px 0 rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
            0 5px 5px -3px rgba(0, 0, 0, 0.14);
        box-shadow: 13px 8px 10px 0 rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
            0 5px 5px -3px rgba(0, 0, 0, 0.14);
        box-sizing: border-box;
    }
    &:hover ${ReviewSourceArrowIcon} {
        float: right;
        margin-right: 3px;
        font-family: Font Awesome;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
    }
`;

export const ReviewSourceIcon = styled.span`
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-right: 16px;
`;

export const ReviewSourceIconImage = styled.img`
    display: inline-block;
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
`;

export const ReviewSourceTitle = styled.span`
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    display: inline-block;
    vertical-align: middle;
`;

export const ReviewSourceSeeMoreLink = styled.a`
    font-family: Roboto;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #828b98;
    text-decoration: underline;
    tex-talign: center;
    margin-bottom: 24px;
    display: block;
    width: 100%;
`;

export const ReviewSourceLink = styled.a`
    text-decoration: none;
    display: block;
    width: 100%;
    color: #000;
    font-size: 18px;
`;

export const PlainLink = styled.a`
    text-decoration: none;
    display: block;
    &:active,
    &:clicked,
    &:visited {
        text-decoration: none;
    }
`;

export const CompanyDetailContainer = styled.div`
    padding: 0 16px;
`;
