import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    text-align: center;
    background: #ffffff;
    padding-top: 61px;
    margin-bottom: 90px;
    margin-top: 60px;
`;

const Title = styled.h2`
    font-family: Roboto;
    font-weight: 900;
    font-size: 32px;
    line-height: 36px;
    color: #1e2024;
    margin-bottom: 16px;
    margin-top: 0;
`;

const SubTitle = styled.p`
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #1e2024;
    margin-top: 0;
`;

const ButtonBlue = styled.a`
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding: 8px 24px;
    height: 24px;
    background: #1874fd;
    border-radius: 36px;
    margin-top: 32px;
    text-decoration: none;
    color: #ffffff;
    display: inline-block;
    margin-bottom: 61px;
`;

const FooterBGLeft = styled.div`
    position: absolute;
    left: 0;
    bottom: -90px;
    width: calc(30%);
    background-image: url('https://cdn.nicejob.co/local/assets/footer-bg-left.png'); /* The image used */
    background-color: transparent; /* Used if the image is unavailable */
    background-position: right top; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    height: 480px;
`;

const FooterBGRIght = styled.div`
    position: absolute;
    right: 0;
    bottom: -90px;
    width: calc(30%);
    background-image: url('https://cdn.nicejob.co/local/assets/footer-bg-right.png'); /* The image used */
    background-color: transparent; /* Used if the image is unavailable */
    background-position: left top; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    height: 480px;
`;

export default function Footer(): ReactElement {
    return (
        <>
            <FooterBGLeft />
            <FooterBGRIght />
            <Container>
                <Title>{translate({ namespace: 'microsite', key: 'footer.title' })}</Title>
                <SubTitle>{translate({ namespace: 'microsite', key: 'footer.subtitle' })}</SubTitle>
                <ButtonBlue href='https://get.nicejob.co/product/review.new'>
                    {translate({ namespace: 'microsite', key: 'footer.shortcut' })}
                </ButtonBlue>
            </Container>
        </>
    );
}
