import React, { ChangeEvent, ReactElement, ReactNode } from 'react';
import { IScreenType } from '../../index';
import { Container, Logo } from './Header.styles';
import SearchInput, { ISearchInputProps } from './SearchInput';

interface IHeaderProps {
    children?: ReactNode;
    screen: IScreenType;
    onSearchChange: ISearchInputProps['onChange'];
}

export default function Header(props: IHeaderProps): ReactElement {
    const { screen, onSearchChange } = props;
    const is_mobile = screen === 'MOBILE_LANDSCAPE' || screen === 'MOBILE_PORTRAIT';

    return (
        <>
            <Container>
                <Logo alt='NiceJob logo' src='https://cdn.nicejob.co/assets/nicejob-v2.png' />
                {!is_mobile && <SearchInput is_mobile={is_mobile} onChange={onSearchChange} />}
            </Container>
            {is_mobile && <SearchInput is_mobile={is_mobile} onChange={onSearchChange} />}
        </>
    );
}
