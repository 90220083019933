import styled from 'styled-components';
import { IScreenType } from './index';

export const ContentContainer = styled.div<{ screen: IScreenType }>`
    max-width: ${(props): string => getContentContainerMaxWidth(props.screen)};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContentTitle = styled.h1`
    color: #32363d;
    font-family: Roboto;
    font-weight: 900;
    margin-top: 40px;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 8px;
    text-align: center;
`;

export const ContentSubtitle = styled.h3`
    font-family: Roboto;
    margin-top: 0px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 34px;
    color: #828b98;
    font-weight: 400;
    text-align: center;
`;

export const CardContainer = styled.div`
    width: 100%;
`;

export const SeeMoreLink = styled.a`
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: block;
    width: 100%;
    text-align: center;
    color: #1874fd;
    margin-top: 32px;
    margin-bottom: 60px;
    text-decoration: none;
`;

export const LoadingContainer = styled.div`
    text-align: center;
`;

export const LoadingSpinner = styled.img`
    display: inline-block;
    vertical-align: middle;
    width: 32px;
`;

export const Error = styled.h3`
    font-family: Roboto;
    margin-top: 0px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 34px;
    color: #828b98;
    font-weight: 400;
    text-align: center;
`;

export const ErrorIcon = styled.span`
    font-family: Font Awesome;
    font-weight: 900;
    font-size: 16px;
    line-height: 12px;
    color: #ffcc00;
    letter-spacing: 0.15em;
    text-align: left;
    margin-bottom: 4px;
`;

export const MainContainer = styled.div`
    background: #fafafb;
    position: relative;
`;

function getContentContainerMaxWidth(screen: IScreenType): string {
    if (screen === 'DESKTOP') {
        return '1027px';
    } else if (screen === 'TABLET') {
        return '682px';
    } else if (screen === 'MOBILE_LANDSCAPE') {
        return '385px';
    } else {
        return '380px';
    }
}
