/**
 * React entry point
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { MicrositeLocalPage, MicrositeLocalPageProps } from './pages';

//  NB: Using `declare global { interface Window {` in multiple places in the codebase (i.e. in each src/index.tsx file) causes issues
//  - Need to use (window as Window)
interface Window {
    __APP_PROPS__?: MicrositeLocalPageProps;
}

/**
 * 1: Get <App> props
 */
const root = document.getElementById('root');
const app_props = (window as Window).__APP_PROPS__;

/**
 * 2: Hydrate
 */
if (app_props?.page === 'local') {
    ReactDOM.hydrate(<MicrositeLocalPage {...app_props} />, root);
}

// Clear out APP_PROPS after hydration
delete (window as Window).__APP_PROPS__;
