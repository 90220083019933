import React, { ReactElement, ReactNodeArray } from 'react';
import { MasonryContainer, MasonryColumn, MasonryCard } from './Masonry.styles';
import { IScreenType } from '../../index';

/** Globals */
const SCREEN_COLUMN = {
    DESKTOP: 3,
    TABLET: 2,
    MOBILE_LANDSCAPE: 1,
    MOBILE_PORTRAIT: 1,
};
export interface IMasonryProps {
    children: ReactNodeArray;
    screen: IScreenType;
}
export default function Masonry(props: IMasonryProps): ReactElement {
    const { children, screen } = props;
    const column_number = SCREEN_COLUMN[screen];
    const column: {
        [key: string]: ReactNodeArray;
    } = {};

    for (let i = 0; i < column_number; i++) {
        column[`col${i}`] = [];
    }

    for (let i = 0; i < children.length; i++) {
        const column_index = i % column_number;
        column[`col${column_index}`].push(<MasonryCard key={i}>{children[i]}</MasonryCard>);
    }
    const result = [];
    for (let i = 0; i < column_number; i++) {
        result.push(
            <MasonryColumn key={`col${i}`} screen={screen}>
                {column[`col${i}`]}
            </MasonryColumn>
        );
    }

    return <MasonryContainer>{result}</MasonryContainer>;
}
