import styled from 'styled-components';

export const Container = styled.div`
    height: 86px;
    border-bottom: 1px solid #dddfe3;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
`;

export const Logo = styled.img`
    display: inline-block;
    width: 146px;
    height: 68px;
    margin-top: 10px;
    margin-left: 12px;
`;
