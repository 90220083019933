/**
 * Page: microsite/local
 */
import { translate } from '@nicejob-library/internationalization';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { httpRequest } from '../../../../../client-js/common/domutil';
import CompanyCard from './ChildComponents/CompanyCard/CompanyCard';
import Footer from './ChildComponents/Footer/Footer';
import Header from './ChildComponents/Header/Header';
import Masonry from './ChildComponents/Masonry/Masonry';
import {
    CardContainer,
    ContentContainer,
    ContentSubtitle,
    ContentTitle,
    Error,
    ErrorIcon,
    LoadingContainer,
    LoadingSpinner,
    MainContainer,
    SeeMoreLink,
} from './index.styles';

/**  Types  */
export interface MicrositeLocalPageProps {
    page: 'local';
    SDK_DOMAIN: string;
    MICROSITE_DOMAIN: string;
}

export type IScreenType = 'MOBILE_LANDSCAPE' | 'MOBILE_PORTRAIT' | 'TABLET' | 'DESKTOP';
export interface CompanyId {
    id: string;
}
export interface SearchResult {
    next_page: number | null;
    data: Array<CompanyId>;
}

/**
 * Main component that exposed to server side rendering
 * don't use hooks inside this component otherwise the server side rendering will rise an error
 */
export function MicrositeLocalPage(props: MicrositeLocalPageProps): ReactElement {
    const { page, SDK_DOMAIN, MICROSITE_DOMAIN } = props;

    return (
        <LocalPageContainer
            page={page}
            SDK_DOMAIN={SDK_DOMAIN}
            MICROSITE_DOMAIN={MICROSITE_DOMAIN}
        />
    );
}

/**
 * Container page to wrap all main component code
 * this is to trick the server-side-rendering part that doesn't allow hooks (useState, useEffect)
 */
function LocalPageContainer(props: MicrositeLocalPageProps): ReactElement {
    const { SDK_DOMAIN, MICROSITE_DOMAIN } = props;
    const [screen, setScreen] = useState<IScreenType>('DESKTOP');
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<SearchResult['data'] | null>(null);
    const [next_page, setNextPage] = useState<number | null>(null);

    /** on component did mount
     * - load the initial data
     * - set the screen size
     * - register window resize event listeners
     */
    useEffect(() => {
        loadInitialData();
        // run for the first time page loaded;
        handleResize();
        // Add event listener
        window.addEventListener('resize', handleResize);

        return (): void => window.removeEventListener('resize', handleResize);
    }, []);

    /**
     * Fetch initial data on page load
     **/
    function loadInitialData(): void {
        //  Search
        setLoading(true);
        setData(null);

        httpRequest<Record<string, unknown>, SearchResult>({
            method: 'POST',
            url: `${SDK_DOMAIN}/sdk/company-location-search`,
            body: {},
        })
            .then(res => {
                setLoading(false);
                setData(res.data);
                setNextPage(res.next_page);
            })
            .catch(error => {
                setLoading(false);
                setData(null);
            });
    }

    /** set the screen mode based on current widow size */
    function handleResize(): void {
        if (window.innerWidth > 1024) {
            setScreen('DESKTOP');
        } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
            setScreen('TABLET');
        } else if (window.innerWidth >= 481 && window.innerWidth <= 767) {
            setScreen('MOBILE_LANDSCAPE');
        } else {
            setScreen('MOBILE_PORTRAIT');
        }
    }

    /**
     * Make a search back-end request
     */
    function onSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        const input = e.currentTarget || e.target;
        const { value = '' } = input;

        // if the value reset to empty - load initial data
        if (!value.length) {
            loadInitialData();

            return;
        }
        //  Quit if too short
        if (value.length <= 3) {
            setLoading(false);

            return;
        }

        //  Search
        setLoading(true);
        setData(null);

        httpRequest<
            {
                search: string;
            },
            SearchResult
        >({
            method: 'POST',
            url: `${SDK_DOMAIN}/sdk/company-search`,
            body: {
                search: value,
            },
        })
            .then(res => {
                setLoading(false);
                setData(res.data);
                setNextPage(res.next_page);
            })
            .catch(error => {
                setLoading(false);
                setData(null);
            });
    }

    function loadMore(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
        event.preventDefault();
        if (!next_page) {
            return;
        }
        //  Search
        setLoading(true);

        httpRequest<
            {
                page: number;
            },
            SearchResult
        >({
            method: 'POST',
            url: `${SDK_DOMAIN}/sdk/company-location-search`,
            body: {
                page: next_page,
            },
        })
            .then(res => {
                setLoading(false);
                const merged: SearchResult['data'] = Array.isArray(data)
                    ? data.concat(res.data)
                    : res.data;
                setData(merged);
                setNextPage(res.next_page);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    /** @todo: define whether if the value as more data */
    const has_more = next_page;

    return (
        <MainContainer>
            {/* Header: Image */}
            <Header screen={screen} onSearchChange={onSearchChange} />

            <ContentContainer screen={screen}>
                <ContentTitle>
                    {translate({ namespace: 'microsite', key: 'local.title' })}
                </ContentTitle>
                <ContentSubtitle>
                    {translate({ namespace: 'microsite', key: 'local.subtitle' })}
                </ContentSubtitle>
                <CardContainer>
                    {/** Loading state */}

                    {/** Something wrong with the request */}
                    {!data && !loading && (
                        <Error>
                            <ErrorIcon></ErrorIcon>{' '}
                            {translate({ namespace: 'common', key: 'error.something_wrong' })}.
                        </Error>
                    )}

                    {data && data.length === 0 && !loading && (
                        <Error>{translate({ namespace: 'common', key: 'text.no_results' })}</Error>
                    )}
                    {data && (
                        <Masonry screen={screen}>
                            {data.map(company => {
                                return (
                                    <CompanyCard
                                        key={company.id}
                                        MICROSITE_DOMAIN={MICROSITE_DOMAIN}
                                        {...company}
                                        SDK_DOMAIN={SDK_DOMAIN}
                                    />
                                );
                            })}
                        </Masonry>
                    )}
                    {loading && (
                        <LoadingContainer>
                            <LoadingSpinner
                                alt='Loading...'
                                src='https://cdn.nicejob.co/assets/loading-spinner.gif'
                            />
                        </LoadingContainer>
                    )}
                </CardContainer>
            </ContentContainer>
            {has_more && !loading && (
                <SeeMoreLink href='#' onClick={loadMore}>
                    {translate({ namespace: 'microsite', key: 'local.see_more' })}
                </SeeMoreLink>
            )}
            <Footer />
        </MainContainer>
    );
}
